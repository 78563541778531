<template>
  <div>
    <div class="border-bottom">
      <div class="d-flex mt-2 mb-1">
        <p class="h2 font-weight-bold">{{ title }}:</p>
      </div>
      <price-detail-item
        v-for="item in paymentInfo.items"
        v-bind:key="item.name"
      >
        <template #countryChannel>{{ itemLabel(item) }}</template>
        <template #price>{{ itemPrice(item) }}</template>
      </price-detail-item>
      <price-detail-item>
        <template #countryChannel>Payment fee</template>
        <template #icon>
          <payment-extra-fee-info
            :payment-extra-fee="paymentExtraFee"
            :payment-extra-fee-percent="paymentExtraFeePercent"
            :currency="currencyLabel"
            :title="title"
            :payment-info="paymentInfo"
            :interval-unit="intervalUnit"
          />
        </template>
        <template #price
          >{{ currencyLabel }}{{ paymentInfo.paymentFee }}</template
        >
      </price-detail-item>
    </div>
    <div
      class="d-flex justify-content-between font-weight-bold align-items-center border-bottom my-1 pb-1"
    >
      <span class="payment-label">{{ paymentLabel }}</span>
      <div class="d-flex justify-content-between font-weight-bold">
        {{ currencyLabel }}{{ paymentInfo.total }}
        <slot name="period" />
      </div>
    </div>
  </div>
</template>
<script>
import PriceDetailItem from "./PriceDetailItem.vue";
import { roundPrice } from "@/services/utils/util";
import { YEAR_INTERVAL_UNIT } from "@/store/channel-selector";
import PaymentExtraFeeInfo from "@/layouts/components/billing/PaymentExtraFeeInfo.vue";

export default {
  components: { PaymentExtraFeeInfo, PriceDetailItem },
  props: {
    paymentLabel: {
      type: String,
    },
    title: {
      type: String,
    },
    currency: {
      type: String,
    },
    paymentInfo: {
      type: Object,
    },
    intervalUnit: {
      type: String,
    },
    paymentExtraFee: {
      require: false,
      default: 0,
    },
    paymentExtraFeePercent: {
      require: false,
      default: 0,
    },
  },
  computed: {
    intervalUnitMultiplier() {
      return this.intervalUnit === YEAR_INTERVAL_UNIT ? 12 : 1;
    },
    currencyLabel() {
      return this.currency === "EUR" ? "€" : "$";
    },
  },
  methods: {
    itemLabel({ name, quantity = 0 }) {
      return quantity > 0 ? `${name} x ${quantity}` : name;
    },
    itemPrice({ price = 0 }) {
      return `${this.currencyLabel}${roundPrice(
        price * this.intervalUnitMultiplier
      )} `;
    },
  },
};
</script>
<style scoped>
@media (max-width: 450px) {
  .payment-label {
    font-size: 11px;
  }
}
</style>
